import React from 'react'
import { FixedObject } from 'gatsby-image'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import classnames from 'classnames'
import styled from '@emotion/styled'
import useResizeObserver from 'use-resize-observer/polyfilled'

import { colors, durations } from '../styles/variables'
import Instagram from '../images/icons/instagram.svg'
import Logo from '../images/logo.svg'
import ReactiveHero from './ReactiveHero'
import useScrollPositionId from '../scripts/useScrollPositionId'

type HeroImage = {
  childImageSharp: {
    fixed: FixedObject
  }
}

interface HeaderDesktopProps {
  intro: boolean
}

interface HeaderDesktopData {
  site: {
    siteMetadata: {
      instagramUrl: string
    }
  }
  dataJson: {
    hero: {
      _: HeroImage[]
      statement: HeroImage[]
      products: HeroImage[]
      shitsurindo: HeroImage[]
    }
  }
}

const HeaderDesktop: React.FC<HeaderDesktopProps> = ({ intro }) => {
  const data: HeaderDesktopData = useStaticQuery(graphql`
    query HeaderDesktop {
      site {
        siteMetadata {
          instagramUrl
        }
      }
      dataJson {
        hero {
          _ {
            childImageSharp {
              fixed(width: 1440, quality: 90) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          statement {
            childImageSharp {
              fixed(width: 1440, quality: 90) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          products {
            childImageSharp {
              fixed(width: 1440, quality: 90) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          shitsurindo {
            childImageSharp {
              fixed(width: 1440, quality: 90) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const {
    ref: rightNavInnerRef,
    height: rightNavInnerHeight
  } = useResizeObserver()

  const activeId = useScrollPositionId(['about', 'products', 'info'])

  return (
    <Container>
      <StyledReactiveHero imageSets={data.dataJson.hero} />
      <Nav>
        <h1>
          <Link to="/">
            <Logo />
          </Link>
        </h1>
        <RightNav
          visible={!intro}
          style={
            intro
              ? {
                  height: 0,
                  marginTop: 0
                }
              : {
                  height: `${rightNavInnerHeight}px`,
                  marginTop: `3rem`
                }
          }
        >
          <div ref={rightNavInnerRef}>
            <PrimaryList>
              <li
                className={classnames('isAbout', {
                  isActive: activeId === 'about'
                })}
              >
                <a href="/#about">About</a>
              </li>
              <li
                className={classnames('isProducts', {
                  isActive: activeId === 'products'
                })}
              >
                <a href="/#products">Products</a>
              </li>
              <li
                className={classnames('isInformation', {
                  isActive: activeId === 'info'
                })}
              >
                <a href="/#info">Information</a>
              </li>
              <li className="isStore">
                <OutboundLink
                  href="https://shitsurindo-onlinestore.com/?category_id=5ff67c21da019c21ec96e0f5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Store
                </OutboundLink>
              </li>
            </PrimaryList>
            <SocialList>
              <li>
                <a
                  href={data.site.siteMetadata.instagramUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Instagram />
                </a>
              </li>
            </SocialList>
          </div>
        </RightNav>
      </Nav>
      <LeftNav visible={intro}>
        <PrimaryList left>
          <li className="isAbout">
            <a href="/#about">About</a>
          </li>
          <li className="isProducts">
            <a href="/#products">Products</a>
          </li>
          <li className="isInformation">
            <a href="/#info">Information</a>
          </li>
          <li className="isStore">
            <OutboundLink
              href="https://shitsurindo-onlinestore.com/?category_id=5ff67c21da019c21ec96e0f5"
              target="_blank"
              rel="noopener noreferrer"
            >
              Store
            </OutboundLink>
          </li>
        </PrimaryList>
        <SocialList>
          <li>
            <a
              href={data.site.siteMetadata.instagramUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram />
            </a>
          </li>
        </SocialList>
      </LeftNav>
    </Container>
  )
}

export default HeaderDesktop

const Container = styled.header`
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
`

const StyledReactiveHero = styled(ReactiveHero)`
  height: 100%;
  position: absolute;
  width: 100%;
`

const Nav = styled.nav`
  color: ${colors.white};
  padding-left: 3em;
  position: relative;

  h1 {
    font-size: inherit;
    margin: 0;
  }

  h1 a {
    color: inherit;
    display: block;
    text-decoration: none;
  }

  h1 svg {
    display: block;
    fill: currentColor;
    height: 2.5em;
    width: auto;
  }
`

const PrimaryList = styled.ul<{ left?: boolean }>`
  align-items: ${props => (props.left ? 'flex-end' : 'flex-start')};
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  white-space: nowrap;

  li {
    list-style: none;
  }

  li::before {
    background: currentColor;
    border-radius: 100%;
    bottom: 0;
    content: '';
    display: block;
    height: 6px;
    left: calc(-6px - 0.5em);
    margin: auto 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translateY(-0.2rem) scale(0);
    transition: opacity ${durations.short}s, transform ${durations.short}s;
    width: 6px;
  }

  li.isActive::before {
    opacity: 1;
    transform: translateY(-0.2rem);
  }

  a {
    align-items: center;
    color: inherit;
    display: flex;
    height: 3em;
    text-decoration: none;
    transition: color ${durations.normal}s;
  }
`

const SocialList = styled.ul`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }

  a {
    align-items: center;
    color: inherit;
    display: flex;
    height: 3em;
    text-decoration: none;
    transition: color ${durations.normal}s;
  }

  svg {
    fill: currentColor;
    height: 1.25em;
    width: auto;
  }
`

const LeftNav = styled.div<{ visible: boolean }>`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding-right: 3em;
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  position: absolute;
  right: 100%;

  ${PrimaryList} li {
    opacity: ${props => (props.visible ? 1 : 0)};
    transform: translate3d(${props => (props.visible ? 0 : 2)}rem, 0, 0);
    transition: opacity ${durations.short}s, transform ${durations.normal}s;
  }

  ${PrimaryList} li.isAbout {
    transition-delay: 0s;
  }

  ${PrimaryList} li.isProducts {
    transition-delay: 0.05s;
  }

  ${PrimaryList} li.isInformation {
    transition-delay: 0.1s;
  }

  ${PrimaryList} li.isStore {
    transition-delay: 0.15s;
  }

  ${SocialList} {
    opacity: ${props => (props.visible ? 1 : 0)};
    transform: translate3d(${props => (props.visible ? 0 : 2)}rem, 0, 0);
    transition: opacity ${durations.short}s 0.15s,
      transform ${durations.normal}s 0.15s;
  }

  @media (hover: hover) {
    ${PrimaryList} a:hover {
      color: ${colors.black};
    }

    ${SocialList} a:hover {
      color: ${colors.black};
    }
  }
`

const RightNav = styled.div<{ visible: boolean }>`
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  transition: height ${durations.normal * 1.5}s,
    margin-top ${durations.normal * 1.5}s;

  ${PrimaryList} li {
    opacity: ${props => (props.visible ? 1 : 0)};
    transform: translate3d(${props => (props.visible ? 0 : -2)}rem, 0, 0);
    transition: opacity ${durations.short}s, transform ${durations.normal}s;
  }

  ${PrimaryList} li.isAbout {
    transition-delay: 0s;
  }

  ${PrimaryList} li.isProducts {
    transition-delay: 0.05s;
  }

  ${PrimaryList} li.isInformation {
    transition-delay: 0.1s;
  }

  ${PrimaryList} li.isStore {
    transition-delay: 0.15s;
  }

  ${SocialList} {
    opacity: ${props => (props.visible ? 1 : 0)};
    transform: translate3d(${props => (props.visible ? 0 : -2)}rem, 0, 0);
    transition: opacity ${durations.short}s 0.15s,
      transform ${durations.normal}s 0.15s;
  }
`
