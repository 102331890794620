import React, { useEffect, useState, useRef } from 'react'
import { FixedObject } from 'gatsby-image'
import classnames from 'classnames'
import styled from '@emotion/styled'

import useScrollPositionId from '../scripts/useScrollPositionId'

type HeroImage = {
  childImageSharp: {
    fixed: FixedObject
  }
}

interface HeroProps {
  imageSets: { [key: string]: HeroImage[] }
  className?: string
}

const ReactiveHero: React.FC<HeroProps> = ({ imageSets, className }) => {
  const activeId = useScrollPositionId(
    Object.keys(imageSets).filter(x => x !== '_')
  )

  const [activeImageSlug, setActiveImageSlug] = useState<string | null>('_-0')

  const init = useRef<boolean>(false)

  useEffect(() => {
    let id: any
    let index = 0
    const key = activeId || '_'
    const length = imageSets[key].length

    const update = () => {
      index = (index + 1) % length
      setActiveImageSlug(`${key}-${index}`)
      id = setTimeout(update, 6000)
    }

    if (init.current) {
      update()
    } else {
      init.current = true
      id = setTimeout(update, 6000)
    }

    return () => clearTimeout(id)
  }, [imageSets, activeId])

  return (
    <Container className={className}>
      <ul>
        {Object.keys(imageSets).map(key =>
          imageSets[key].map((image, i) => (
            <li
              key={`${key}-${i}`}
              className={classnames({
                isActive: `${key}-${i}` === activeImageSlug
              })}
            >
              <img src={image.childImageSharp.fixed.src} alt="" />
            </li>
          ))
        )}
      </ul>
    </Container>
  )
}

export default ReactiveHero

const Container = styled.div`
  ul {
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
  }

  li {
    height: 100%;
    list-style: none;
    opacity: 0;
    position: absolute;
    transition: opacity 2s;
    width: 100%;
  }

  li.isActive {
    opacity: 1;
  }

  img {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`
