// widths

export const widths = {
  mobileLarge: 768,
  mobileDesktop: 1024,
  desktopLarge: 1536
}

// colors

export const colors = {
  black: 'rgba(0, 0, 0, 1)',
  black75: 'rgba(0, 0, 0, 0.75)',
  black50: 'rgba(0, 0, 0, 0.5)',
  black25: 'rgba(0, 0, 0, 0.25)',
  black10: 'rgba(0, 0, 0, 0.1)',
  black5: 'rgba(0, 0, 0, 0.05)',
  black0: 'rgba(0, 0, 0, 0)',
  white: 'rgba(255, 255, 255, 1)',
  productPlaceholderTopRight: '#daddd6',
  productPlaceholderBottomLeft: '#ebedec',
  link: 'rgba(94, 123, 151, 1)',
  link50: 'rgba(94, 123, 151, 0.5)',
  link25: 'rgba(94, 123, 151, 0.25)'
}

// sizes

const sizeMul = 1.25
export const sizes = {
  // font
  larger1: sizeMul ** 1,
  larger2: sizeMul ** 2,
  larger3: sizeMul ** 3,
  smaller1: (1 / sizeMul) ** 1,
  smaller2: (1 / sizeMul) ** 2,
  smaller3: (1 / sizeMul) ** 3,

  // item
  item: 13
}

// line-height

export const lineHeight = {
  normal: 1.75,
  loose: 2
}

// border-radius

export const borderRadius = 0.2

// durations

export const durations = {
  short: 0.25,
  normal: 0.5
}

// font-family

export const fontFamilies = {
  serif: `'DidoteTextPro-Regular', 'YuMincho', 'Yu Mincho', 'Hiragino Mincho ProN', 'Noto Serif JP', serif`,
  sans: `-apple-system, 'BlinkMacSystemFont', 'YuGochic', 'Yu Gothic', 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', 'Noto Sans JP', sans-serif`
}

// uppercase-offset

export const uppercaseOffset = 0.15
