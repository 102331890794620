import React from 'react'
import { Global, css } from '@emotion/core'
import { graphql, useStaticQuery } from 'gatsby'
import Helmet from 'react-helmet'
import MediaQeury from 'react-responsive'
import styled from '@emotion/styled'

import { widths, colors, lineHeight, fontFamilies } from '../styles/variables'
import HeaderDesktop from '../components/HeaderDesktop'
import HeaderMobile from '../components/HeaderMobile'

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]', {
    speed: 700,
    speedAsDuration: true
  })
}

interface StaticQueryData {
  site: {
    siteMetadata: {
      title: string
      description: string
    }
  }
}

const cssGlobal = css`
  html {
    font-size: calc(
      13px + 4 * ((100vw - 375px) / ${widths.mobileDesktop - 375})
    );

    @media (min-width: ${widths.mobileDesktop}px) {
      font-size: calc(
        14px + 2 *
          ((100vw - ${widths.mobileDesktop}px) / ${widths.mobileDesktop})
      );
    }
  }

  body {
    background: ${colors.white};
    color: ${colors.black75};
    font-family: ${fontFamilies.serif};
    line-height: ${lineHeight.normal};
    margin: 0;
  }
`

interface IndexLayoutProps {
  intro: boolean
}

const IndexLayout: React.FC<IndexLayoutProps> = ({ intro, children }) => {
  const data: StaticQueryData = useStaticQuery(graphql`
    query IndexLayoutQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <>
      <Global styles={cssGlobal} />
      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          {
            name: 'description',
            content: data.site.siteMetadata.description
          }
        ]}
      >
        <link
          href="https://fonts.googleapis.com/css?family=Noto+Serif+JP&display=swap&subset=japanese"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap&subset=japanese"
          rel="stylesheet"
        />
        <link href="/webfonts/MyFontsWebfontsKit.css" rel="stylesheet" />
      </Helmet>
      <Container onTouchStart={() => {}}>
        <Header>
          <MediaQeury query={`(max-width: ${widths.mobileDesktop - 1}px)`}>
            <HeaderMobile />
          </MediaQeury>
          <MediaQeury query={`(min-width: ${widths.mobileDesktop}px)`}>
            <HeaderDesktop intro={intro} />
          </MediaQeury>
        </Header>
        <Main>{children}</Main>
      </Container>
    </>
  )
}

export default IndexLayout

const Container = styled.div``

const Header = styled.div`
  @media (min-width: ${widths.mobileDesktop}px) {
    height: 100%;
    left: 50%;
    position: fixed;
    width: 50%;
  }
`

const Main = styled.main`
  @media (min-width: ${widths.mobileDesktop}px) {
    width: 50%;
  }
`
