import React, { useState, useEffect, useRef } from 'react'
import { FixedObject } from 'gatsby-image'
import { Link, useStaticQuery, graphql } from 'gatsby'
import classnames from 'classnames'
import styled from '@emotion/styled'
import useResizeObserver from 'use-resize-observer/polyfilled'

import { colors, durations, uppercaseOffset } from '../styles/variables'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Instagram from '../images/icons/instagram.svg'
import Logo from '../images/logo.svg'
import ReactiveHero from './ReactiveHero'
import useScrollPositionId from '../scripts/useScrollPositionId'

type HeroImage = {
  childImageSharp: {
    fixed: FixedObject
  }
}

interface HeaderMobileData {
  site: {
    siteMetadata: {
      instagramUrl: string
    }
  }
  dataJson: {
    hero: {
      _: HeroImage[]
    }
  }
}

const HeaderMobile: React.FC = () => {
  const data: HeaderMobileData = useStaticQuery(graphql`
    query HeaderMobile {
      site {
        siteMetadata {
          instagramUrl
        }
      }
      dataJson {
        hero {
          _ {
            childImageSharp {
              fixed(width: 750, quality: 90) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const ref = useRef<HTMLElement>(null)
  const { ref: navRef, height: navHeight } = useResizeObserver()
  const [navFixed, setNavFixed] = useState<boolean>(false)
  const [navActive, setNavActive] = useState<boolean>(true)

  useEffect(() => {
    const updatePosition = () => {
      if (ref.current) {
        setNavFixed(ref.current.getBoundingClientRect().bottom < navHeight)
      }
    }

    addEventListener('scroll', updatePosition)
    addEventListener('resize', updatePosition)

    return () => {
      removeEventListener('scroll', updatePosition)
      removeEventListener('resize', updatePosition)
    }
  }, [navHeight])

  useEffect(() => {
    if (!navFixed) return

    let lastScrollY = pageYOffset

    const updateNavActivity = () => {
      if (pageYOffset < innerHeight * 1.5) {
        setNavActive(true)
        return
      }

      if (pageYOffset < lastScrollY) {
        setNavActive(true)
      } else if (pageYOffset > lastScrollY) {
        setNavActive(false)
      }
      lastScrollY = pageYOffset
    }

    addEventListener('scroll', updateNavActivity)

    return () => {
      removeEventListener('scroll', updateNavActivity)
    }
  }, [navFixed])

  const [height, setHeight] = useState<number>(0)

  useEffect(() => {
    setHeight(height => height || window.innerHeight)
  }, [])

  const activeId = useScrollPositionId(['about', 'products', 'info'])

  return (
    <Container style={{ height: `${height}px` }} ref={ref}>
      <Hero>
        <StyledReactiveHero imageSets={data.dataJson.hero} />
        <h1>
          <Link to="/">
            <Logo />
          </Link>
        </h1>
      </Hero>
      <Nav
        ref={navRef}
        style={{
          ...(navFixed ? { position: 'fixed' } : { position: 'relative' }),
          ...(navActive
            ? { transform: 'translateY(0)' }
            : { transform: 'translateY(-100%)' })
        }}
      >
        <ul>
          <li className={classnames({ isActive: activeId === 'about' })}>
            <a href="/#about">About</a>
          </li>
          <li className={classnames({ isActive: activeId === 'products' })}>
            <a href="/#products">Products</a>
          </li>
          <li className={classnames({ isActive: activeId === 'info' })}>
            <a href="/#info">Info</a>
          </li>
          <li>
            <OutboundLink
              href="https://shitsurindo-onlinestore.com/?category_id=5ff67c21da019c21ec96e0f5"
              target="_blank"
              rel="noopener noreferrer"
            >
              Store
            </OutboundLink>
          </li>
          <li>
            <a
              href={data.site.siteMetadata.instagramUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram />
            </a>
          </li>
        </ul>
      </Nav>
    </Container>
  )
}

export default HeaderMobile

const Container = styled.header`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

const StyledReactiveHero = styled(ReactiveHero)`
  height: 100%;
  width: 100%;
`

const Hero = styled.div`
  color: ${colors.white};
  height: calc(100% - 4rem);
  overflow: hidden;
  position: relative;

  h1 {
    bottom: 0;
    display: flex;
    font-size: inherit;
    justify-content: center;
    margin: 0;
    position: absolute;
    text-align: center;
    white-space: nowrap;
    width: 100%;
  }

  h1 a {
    color: inherit;
    display: block;
    padding: 1.5em;
    text-decoration: none;
  }

  h1 svg {
    display: block;
    fill: currentColor;
    height: 1.5em;
    width: auto;
  }
`

const Nav = styled.nav`
  background: ${colors.white};
  height: 4rem;
  text-transform: uppercase;
  transition: transform ${durations.short}s;
  width: 100%;
  z-index: 1;

  ul {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    position: relative;
  }

  li + li {
    margin-left: 1.5em;
  }

  li::before {
    background: currentColor;
    border-radius: 100%;
    content: '';
    display: block;
    height: 4px;
    left: 0;
    margin: 0 auto;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 100%;
    transform: scale(0);
    transition: opacity ${durations.normal}s, transform ${durations.normal}s;
    width: 4px;
  }

  li.isActive::before {
    opacity: 1;
    transform: scale(1);
  }

  a {
    align-items: center;
    bottom: -${uppercaseOffset}em;
    color: inherit;
    display: flex;
    position: relative;
    text-decoration: none;
  }

  svg {
    fill: currentColor;
    height: 1em;
    position: relative;
    top: -${uppercaseOffset}em;
    width: auto;
  }
`
