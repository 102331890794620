import { useState, useEffect } from 'react'

const useScrollPositionId = (ids: string[]) => {
  const [activeId, setActiveId] = useState<string | null>(null)

  useEffect(() => {
    const updateActiveId = () => {
      const threshold = window.innerHeight / 2
      let currentTop = Number.NEGATIVE_INFINITY
      let currentId: string | null = null

      for (const id of ids) {
        const el = document.getElementById(id)
        if (!el) continue

        const { top } = el.getBoundingClientRect()
        if (top > threshold) continue
        if (top <= currentTop) continue

        currentTop = top
        currentId = id
      }

      setActiveId(currentId)
    }

    addEventListener('scroll', updateActiveId)
    addEventListener('resize', updateActiveId)
    updateActiveId()

    return () => {
      removeEventListener('scroll', updateActiveId)
      removeEventListener('resize', updateActiveId)
    }
  }, [ids])

  return activeId
}

export default useScrollPositionId
